import { Grid } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { LocaleContext } from '../i18n/LocaleContext';
import { userLocale } from '../i18n/user-locale';
import Menu from "./Menu";

import CircularProgress from '@mui/material/CircularProgress';
import Footer from "./footer";

const Loading = () => (

    <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
    >
        <Grid item xs={3}>
            <CircularProgress />
        </Grid>
    </Grid>

)

export default function LocaleMenu({id}) {

    const [searchParams] = useSearchParams();
    const restaurantId = (!id) ? searchParams.get("restaurant_id") : id;
    const [menu, setMenu] = useState(null);
    const [catalog, setCatalog] = useState(null);
    const [theme, setTheme] = useState(null);
    const [locale, setLocale] = useState(null);
    const [messages, setMessages] = useState(null);
    const [localeConfig, setLocaleConfig] = useState(null);
    const [organization, setOrganization] = useState(null);

    console.log(restaurantId);


    useEffect(() => {


        if (!localeConfig) {

            import(`../data/${restaurantId}/localeConfig.js`)
                .then((module) => {
                    setLocaleConfig(module.localeConfig);
                })
                .catch((err) =>
                    console.error(`Error loading localeConfig ${restaurantId}: `, err)
                );

        } 
        
        if(localeConfig) {

            setLocale(userLocale(localeConfig))

            
        }

        if(locale) {
            import(`../data/${restaurantId}/lang/${locale}.json`)
                .then((messages_) => setMessages(messages_))
                .catch((err) =>
                    console.error(`Error loading messages for locale ${locale}: `, err)
                );

        }

        if (!catalog) {

            import(`../data/${restaurantId}/catalogData.js`)
                .then((module) => {
                    setCatalog(module.catalog);
                })
                .catch((err) =>
                    console.error(`Error loading catalog ${restaurantId}: `, err)
                );

        }

        if (!menu) {

            import(`../data/${restaurantId}/itemData.js`)
                .then((module) => {
                    setMenu(module.menu);
                })
                .catch((err) =>
                    console.error(`Error loading menu ${restaurantId}: `, err)
                );

        }

        if (!theme) {
            import(`../data/${restaurantId}/theme.js`)
                .then((module) => {
                    setTheme(module.theme);
                })
                .catch((err) =>
                    console.error(`Error loading theme ${restaurantId}: `, err)
                );
        }


        if (!organization) {
            import(`../data/${restaurantId}/organization.js`)
                .then((module) => {
                    setOrganization(module.organization);
                })
                .catch((err) =>
                    console.error(`Error loading organization ${restaurantId}: `, err)
                );
        }

    }, [localeConfig, catalog, menu, theme, restaurantId, locale, organization]);




    return (
        (!localeConfig || !catalog || !menu || !theme || !messages || !organization) ? (
            <Loading />
        ) : (

            <LocaleContext.Provider value={{ locale, setLocale }}>
                <IntlProvider
                    locale={locale}
                    defaultLocale={localeConfig.defaultLocale}
                    messages={messages}
                >
                    <ThemeProvider theme={responsiveFontSizes(theme)} >
                        <CssBaseline />
                        <Menu catalog={catalog} menu={menu} localeConfig={localeConfig} />
                        <Footer organization={organization} />
                    </ThemeProvider>
                </IntlProvider>
            </LocaleContext.Provider>
        )
    );
}
