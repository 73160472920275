import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram } from "@mui/icons-material";


import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';

export default function Footer() {
    return (
        <Paper sx={{
            mt: '50px',
            backgroundColor: (theme) =>
                theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
        }} component="footer" square variant="outlined">


            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Hakkımızda
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            2022 yılından bu yana nefis künefemizi Yalova'da siz künefe severlere sunuyoruz. Afiyet olsun!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Bize Ulaşın
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Adnan Menderes Mahallesi,  <br />Şehit Yaşar Kuş Caddesi, <br/> Merkez/Yalova
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Telefon: +90 5424032727
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Bizi Takip Edin
                        </Typography>
                        <Link href="https://m.facebook.com/people/Gaziantep-k%C3%BCnefe-baklava-kebap/100087727749574/" color="inherit">
                            <Facebook />
                        </Link>
                        <Link
                            href="https://www.instagram.com/gaziantepkunefebaklavakebap/"
                            color="inherit"
                            sx={{ pl: 1, pr: 1 }}
                        >
                            <Instagram />
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {"Copyright © "}
                        <Link color="inherit" href="./yalova_ozdemir_bey_kunefe">
                            yalova_ozdemir_bey_kunefe
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
}