import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormattedMessage } from 'react-intl';

import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';

export default function Footer({ organization }) {
    return (
        <Paper sx={{
            mt: '50px',
            // backgroundColor: (theme) =>
            //     theme.palette.mode === "light"
            //         ? theme.palette.grey[200]
            //         : theme.palette.grey[800],
        }} 
        
        component="footer" square variant="outlined">


            <Container maxWidth="lg">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            <FormattedMessage id={"footer_about_title"} />

                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <FormattedMessage id={"organization_about"} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            <FormattedMessage id={"footer_reach_us_title"} />
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <FormattedMessage id={"organization_address"} />
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                            <PhoneAndroidIcon fontSize="small" color="inherit" />
                            <FormattedMessage id={"organization_phone"} />
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            <FormattedMessage id={"footer_follow_us_title"} />
                        </Typography>
                        {
                            organization.facebook &&
                            <Link href={organization.facebook} color="inherit">
                                <Facebook />
                            </Link>
                        }
                        {
                            organization.instegram &&
                            <Link href={organization.instegram} color="inherit">
                                <Instagram />
                            </Link>
                        }
                        {
                            organization.twitter &&
                            <Link href={organization.twitter} color="inherit">
                                <Twitter />
                            </Link>
                        }
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {"Copyright © "}
                        {/* <Link color="inherit" href="./yalova_ozdemir_bey_kunefe">
                            yalova organica
                        </Link>{" "} */}
                        {new Date().getFullYear()}
                        {/* {"."} */}
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
}