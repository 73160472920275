import { React, useState } from "react";
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import IconButton from '@mui/material/IconButton';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { Link as ScrollLink } from 'react-scroll';


import Footer from './footer';

import { Accordion, Box, Button, CardHeader, Link, ListItemButton, Paper, Stack } from '@mui/material';

import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';

import Swap from './AutoPlaySwipeableViews';

import { Divider } from '@mui/material';

import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { FormattedMessage } from 'react-intl';


import { useIntl } from 'react-intl';

import Avatar from '@mui/material/Avatar';
import LangSwitcher from '../i18n/LangSwitcher';
import { useDocL10n } from "../i18n/useDocL10n";


function CatalogHeader({ catalogKey, catalogTitleTranslateId }) {
    return (
        <Divider sx={{ pt: 3 }} id={catalogKey}>


            <Typography variant="h5" align="center" gutterBottom>
                <FormattedMessage id={catalogTitleTranslateId} defaultMessage={catalogTitleTranslateId} />
            </Typography>
        </Divider>
    )
}

function MenuCardHeader(args) {

    const titleText =
        <Typography variant='h6' sx={{ pl: '5px' }} >
            <FormattedMessage id={args.menuTitleTranslateId} defaultMessage={args.menuTitleTranslateId} />
        </Typography>

    return (
        <CardHeader title={titleText} />
    )
}

function MenuCardOutline(args) {

    if (args.card.outlines != null && args.card.outlines.length > 0) {
        return (
            <Box>
                {

                    args.card.outlines.map((value) => {
                        const outlineTranslateId = "menu_" + args.card.key + "_outlines_outline_" + value.key;
                        return (
                            <Typography key={value.key} variant="body2" textAlign={'left'} sx={{ display: 'list-item' }}>
                                <FormattedMessage id={outlineTranslateId} defaultMessage={outlineTranslateId} />
                            </Typography>
                        );
                    }
                    )
                }
            </Box>
        )
    }

    return null;
}

function MenuCardDescription({ intl, card }) {

    const descriptionTranslateId = "menu_" + card.key + "_description";
    const descriptionExists = !!intl.messages[descriptionTranslateId];

    if (!descriptionExists) {
        return null;
    }

    const description = intl.formatMessage({ id: "menu_" + card.key + "_description" })

    const descriptionText =
        <Typography variant="body1" textAlign={'left'} style={{ whiteSpace: 'pre-line' }}>
            {description}
        </Typography>;

    if (description.length > 200) {
        return (
            <Accordion elevation={0} sx={{ backgroundColor: 'inherit', p: 0 }}>
                <AccordionSummary sx={{ p: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>
                        <FormattedMessage id='menu_content' defaultMessage='menu_content' />
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {descriptionText}
                </AccordionDetails>
            </Accordion>
        )
    } else {
        return (
            descriptionText
        )
    }
}


function MenuPriceList(args) {
    const intl = useIntl()
    return (
        <List key={args.card.key} divider={<Divider />}>
            {args.card.priceList.map((value) => {
                const menuPriceTitleTranslateId = "menu_" + args.card.key + "_price_list_" + value.key + "_title"
                const menuPricesubtitleTranslateId = "menu_" + args.card.key + "_price_list_" + value.key + "_subtitle"

                const subTitleExists = !!intl.messages[menuPricesubtitleTranslateId];

                const flexRow = { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' };

                return (
                    <Container key={value.key} sx={{ px: 0 }}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }} disablePadding>


                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>

                                <Typography sx={flexRow}>
                                    <FormattedMessage id={menuPriceTitleTranslateId} defaultMessage={menuPriceTitleTranslateId} />
                                </Typography>

                                {
                                    subTitleExists &&
                                    <Typography variant='body2' sx={flexRow}>
                                        <FormattedMessage id={menuPricesubtitleTranslateId} defaultMessage={menuPricesubtitleTranslateId} />

                                    </Typography>
                                }
                            </Box>
                            {
                                value.price > 0 &&
                            <Typography>
                                {value.price}
                                <IconButton sx={{ px: 0, color: 'inherit' }}>
                                    <CurrencyLiraIcon fontSize='small' />
                                </IconButton>
                            </Typography>
                        }
                        {
                            value.price === 0 && 
                        <Typography variant='body2' sx={{fontWeight: 'bold'}}>
                            <FormattedMessage id="app_free" defualtMessage="app_free" />
                            </Typography>
                        }
                        </ListItem>
                        <Divider />
                    </Container>
                );
            })}
        </List>
    )
}

function NavBar({ catalog, localeConfig }) {

    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <AppBar position="sticky">
            <Toolbar
                // sx={{px: 1}}
                component={Stack}
                direction="row"
                sx={{ px: 1, justifyContent: 'center', flexWrap: 'nowrap' }}
            >

                <Stack 
                //   divider={<Divider orientation="vertical" flexItem />}

                spacing={2} direction={'row'} useFlexGap flexWrap="wrap"
                justifyContent={'center'}
                sx={{py: 2}}
                >

                    {
                        catalog.map((item) => {
                            const catalogTitleTranslateId = "catalog_" + item.key + "_title";
                            return (
                                // <ListItem
                                // selected={selectedIndex === item.key}
                                // key = { catalogTitleTranslateId }
                                // >
                                <Box sx={{borderBottom: 1}} key={item.key}>
                                <ScrollLink
                                    onClick={(event) => handleListItemClick(event, item.key)}

                                    to={item.key} spy={true} smooth={true}
                                    offset={catalog.length > 4 ? -130: -70}
                                >

                                    <Typography noWrap>
                                        <FormattedMessage id={catalogTitleTranslateId} defaultMessage={catalogTitleTranslateId} />
                                    </Typography>
                                </ScrollLink>
                                </Box>
                                // </ListItem>
                            );
                        })
                    }


                </Stack>
            </Toolbar>
        </AppBar>
    )
}

function MenuItem({ intl, catalog, itemData }) {
    const allCategories = [...new Set(catalog.map((item) => item.key))]

    return (
        <main > {

            allCategories.map((catId) => {
                const catalogTitleTranslateId = "catalog_" + catId + "_title"
                return (
                    <Container
                        key={catalogTitleTranslateId}
                        sx={{
                            py: 4,
                            // border: 3,
                            // backgroundColor: '#8c9727',
                            // backgroundImage: `url(${imageURL})`
                        }} maxWidth="md">
                        <CatalogHeader catalogKey={catId} catalogTitleTranslateId={catalogTitleTranslateId} />

                        <Grid container
                            columnSpacing={{ xs: 1, sm: 1, md: 4 }}
                            rowSpacing={{ xs: 2, sm: 2, md: 4 }}
                        >
                            {
                                (itemData.filter((item) => item.catalogId === catId)).map((card) => {

                                    const menuTitleTranslateId = "menu_" + card.key + "_title"
                                    return (

                                        <Grid item key={card.key} xs={12} sm={6} md={6}>

                                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <MenuCardHeader card={card} menuTitleTranslateId={menuTitleTranslateId} />


                                                <CardMedia children={<Swap imgContainer={card.images} />} />


                                                <CardContent sx={{ flexGrow: 1, p: 0 }} >

                                                    <Box
                                                        sx={{ px: '5px' }}
                                                    >
                                                        {/* {
                                                            card.priceList.length > 0 && 
                                                            
                                                            <MenuPriceList card={card} />
                                                        } */}
                                                        <MenuPriceList card={card} />
                                                    </Box>


                                                    {
                                                        <Box sx={{ px: '5px' }}>
                                                            <MenuCardOutline card={card} />
                                                            <MenuCardDescription intl={intl} card={card} />
                                                        </Box>
                                                    }
                                                    {/* {
                                                    card.priceList.length === 0 &&
                                                        <Box sx={{ px: '5px' }}>
                                                            <MenuCardOutline card={card} />
                                                            <MenuCardDescription description={description} />
                                                        </Box>
                                                    } */}

                                                </CardContent>

                                            </Card>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>

                )
            })
        } </main >
    );
}

function OrganizationSubtitle({ intl }) {
    const transId = "organization_subtitle";
    const exists = !!intl.messages[transId];

    if (!exists) {
        return null;
    }
    return (
        <Typography variant="h6">
            <FormattedMessage id={transId} defaultMessage={transId} />
        </Typography>
    );



}

export default function Menu({ catalog, menu, localeConfig }) {


    console.log(window.location.pathname);


    useDocL10n(localeConfig);

    const intl = useIntl()
    return (
        <main>
            <AppBar
                position="relative" sx={{
                    // bgcolor: 'white',

                }}>


                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 0,
                        borderRadius: 1,
                    }}
                >
                    <IconButton>
                        <Avatar>
                            <FormattedMessage id='organization_title' defaultMessage='organization_title' />
                        </Avatar>
                    </IconButton>
                    <Typography variant='h5' >
                        <FormattedMessage id='organization_title' defaultMessage='organization_title' />
                    </Typography>


                    <LangSwitcher localeConfig={localeConfig} />

                </Toolbar>

                <Divider />
                <OrganizationSubtitle intl={intl} />

            </AppBar>
            <NavBar catalog={catalog} localeConfig={localeConfig} />

            <MenuItem intl={intl} catalog={catalog} itemData={menu} />
        </main>
    );
}
