import { match } from "@formatjs/intl-localematcher";
import { browserLocales } from "./browser-locales";
import { getStoredLocale } from "./stored-locale";

export function userLocale(localeConfig) {
  const storedLocale = getStoredLocale();
  if (storedLocale) return storedLocale;

  const appLocales = Object.keys(localeConfig.locales);
  return match(browserLocales(), appLocales, localeConfig.defaultLocale);
}
