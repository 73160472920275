import React, { useState } from 'react'
import Menu from './Menu'
import Categories from './Categories'
import items from './data'
import Header from "./header";
import App from "./App.css"

const allCategories = ['hepsi', ...new Set(items.map((item) => item.category))]

function Demo1() {
  const [menuItems, setMenuItems] = useState(items)
  const [categories, setCategories] = useState(allCategories)

  const filterItems = (category) => {
    if (category === 'hepsi') {
      setMenuItems(items)
      return
    }
    const newItems = items.filter((item) => item.category === category)
    setMenuItems(newItems)
  }
  return (
    <main className="App">
    <Header/>
      <section className="menu section">
        <div className="title">
          <h2>Menümüz</h2>
          <div className="underline"></div>
        </div>
        <Categories categories={categories} filterItems={filterItems} />
        <Menu items={menuItems} />
      </section>
    </main>
  )
}

export default Demo1
