import { React } from 'react';

import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import itemData from './itemData';

import IconButton from '@mui/material/IconButton';


import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CssBaseline from '@mui/material/CssBaseline';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import catalogData from './catalogData';
import Footer from './footer';

import { useNavigate, useSearchParams } from "react-router-dom";



const defaultTheme = createTheme();


function Menu(catalogId) {
    const filteredMenu = (itemData.filter((item) => item.catalogId === catalogId.catalogId))

    return (
        <main>
            <Container sx={{ py: 8 }} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    {filteredMenu.map((card) => (
                        <Grid item key={card.key} xs={12} sm={6} md={4}>
                            <Card
                                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                            >

                                {/* <CardHeader
                      title={card.title}
                    /> */}

                                <CardMedia
                                    component="div"
                                    sx={{
                                        // 16:9
                                        pt: '56.25%',
                                    }}
                                    image={card.img}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>

                                    <Typography gutterBottom variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                    <Typography>

                                        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            {card.priceList.map((value) => {
                                                const labelId = `checkbox-list-secondary-label-${value.title}`;
                                                return (
                                                    <ListItem
                                                        key={value.key}
                                                        sx={{ borderBottom: 1 }}

                                                        disablePadding
                                                    >

                                                        <ListItemText id={labelId}  primaryTypographyProps={{fontSize: '20px'}}  primary={value.title} sx={{ color: '#2E3B55' }} />

                                                        <ListItemText sx={{ color: '#2E3B55' }} primaryTypographyProps={{fontSize: '20px'}} primary={`${value.price}TL`} align='right' />

                                                    </ListItem>
                                                );
                                            })}
                                        </List>

                                    </Typography>
                                </CardContent>

                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </main>
    );
}

export default function MenuList() {  
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const catalogId = searchParams.get("catalogId");
    const menuText = catalogData.filter((item) => item.key === catalogId)[0].title;

    const handleOpen = () => {
        navigate(-1);
    };



    return (

        <ThemeProvider theme={defaultTheme} >
            <CssBaseline />
            <main>

                <AppBar position="relative" style={{ background: '#2E3B55' }}>

                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            bgcolor="background.paper"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleOpen}
                        >
                            <ArrowBackIcon sx={{ xl: 2 }} />
                        </IconButton>

                        <Typography variant="h6" color="white" noWrap align='center'>
                            {menuText}
                        </Typography>
                    </Toolbar>


                </AppBar>


                <Menu catalogId={catalogId} />

            </main>
            <Footer />

        </ThemeProvider>
    );
}
