
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { React } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';


import Avatar from '@mui/material/Avatar';
import logo from './ozdemir_bey_logo.jpg';
import catalogData from './catalogData';
import Footer from './footer';

import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function TitlebarImageList() {
  const navigate = useNavigate();

  const handleClose = (id) => {
    navigate('./menu?catalogId=' + id);
  };

  const matches = useMediaQuery('(min-width:280px)');

  // widthSize = matches ? 360: 180;

  return (

    <ThemeProvider theme={defaultTheme} >
      <CssBaseline />
      <main>

        <AppBar position="relative" style={{ background: '#2E3B55' }}>
          <Toolbar>
            <IconButton><Avatar src={logo} sx={{ mr: 2 }} /></IconButton>
            <Typography variant="h6" color="white" noWrap>
              Gaziantep Künefe
            </Typography>
          </Toolbar>

        </AppBar>

        <container>
          <Box
            sx={{
              bgcolor: 'background.paper',
              pt: 3,
              pb: 2,
            }}
          >
            <Container maxWidth="sm">
              <Typography
                component="h4"
                variant="h4"
                align="center"
                color="text.primary"
                bgcolor="background.paper"
                gutterBottom
              >
                Menümüz
              </Typography>
            </Container>
            <div className="underline"></div>
          </Box>

          <Container maxWidth="md">
            <ImageList gap={4}

              cols={matches ? 2 : 1}
              // sx={{
              //   gridTemplateColumns: 'repeat(auto-fill,minmax(360px, 1fr))!important',
              //   overflow: 'hidden'

              // }}
            >
              {catalogData.map((item) => (


                <ImageListItem key={item.img}

                 
                  sx={{
                    overflow: 'hidden', bgcolor: 'background.paper', maxHeight: 360,
                    cursor: 'pointer'
                  }}

                >

                  <img
                   onClick={e => handleClose(e.target.id)}
                    srcSet={`${item.img}?w=256&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=256&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                    id={item.key}
                  />

                  <ImageListItemBar
                  onClick={() => handleClose(item.key)}
                    title={item.title}
                    subtitle={item.author}
                    
                    // actionIcon={
                      
                    //   <IconButton
                    //   id={item.key}
                    //   onClick={e => handleClose(e.target.id)}
                    //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    //     aria-label={`info about ${item.title}`}
                    //   >
                    //   </IconButton>
                    // }
                  />
                </ImageListItem>

              ))}
            </ImageList>
          </Container>
        </container>
      </main>
      <Footer />

    </ThemeProvider>
  );
}

