const menu = [
  {
    id: 1,
    title: 'Tek Kişilik Kahvaltı Tabağı',
    category: 'kahvaltı',
    price: 175,
    img:
      'https://www.brooklynyalova.com/uploads/1691508050-TEK-K%C4%B0%C5%9E%C4%B0L%C4%B0K-KAHVALTI-TABA%C4%9EI2.jpg  ',
    desc: `BEYAZ PEYNİR, TAZE KAŞAR, SİYAH ZEYTİN, ÇİZİK YEŞİL ZEYTİN, DOMATES, SALATALIK, YEŞİLLİK, ÇİKOLATA, ÇİLEK REÇELİ, BAL, TEREYAĞ, SİGARA BÖREĞİ, SOSİS, PATATES KIZARTMASI, HAŞLANMIŞ YUMURTA, MEVSİM MEYVESİ, 2 ADET ÇAY`,
  },
  {
    id: 2,
    title: 'Kaşarlı Izgara Köfte',
    category: 'ana yemek',
    price: 170,
    img:
      'https://www.brooklynyalova.com/uploads/1691502626-ka%C5%9Farl%C4%B1%20%C4%B1zgara%20k%C3%B6fte.jpg',
    desc: `KAŞAR PEYNİR DOLGULU GELENEKSEL IZGARA KÖFTE LEZZETİ KÖZLENMİŞ GARNİTÜR,ÖZEL SOSLAR VE PARMAK PATATES EŞLİĞİNDE`,
  },
  {
    id: 3,
    title: 'kofteli wrap',
    category: 'ana yemek',
    price: 140,
    img: 'https://www.brooklynyalova.com/uploads/1691510719-k%C3%B6fteli.jpg',
    desc: `IZGARA KÖFTE, AKDENİZ YEŞİLLİKLERİ, DOMATES, PATATES KIZARTMASI VE ÖZEL SOSLAR İLE SERVİS EDİLİR.`,
  },
  {
    id: 4,
    title: 'beyaz peynirli gözleme',
    category: 'aparatifler',
    price: 90,
    img:
      'https://www.brooklynyalova.com/uploads/1691508072-GOZLEME-KAPAK.jpg',
    desc: `BEYAZ PEYNİR, PATATES KIZARTMASI, DOMATES, SALATALIK, ZEYTİN VE ÖZEL SOSLAR İLE SERVİS EDİLİR`,
  },
  {
    id: 5,
    title: 'karışık tost',
    category: 'aparatifler',
    price: 90,
    img:
      'https://www.brooklynyalova.com/uploads/1691503242-karisik-tost-e192.jpg',
    desc: `PATATES KIZARTMASI, DOMATES, SALATALIK, ZEYTİN VE ÖZEL SOSLAR İLE SERVİS EDİLİR`,
  },
  {
    id: 6,
    title: '2 Kişilik Serpme Kahvaltı',
    category: 'kahvaltı',
    price: 380,
    img:
      'https://static.ticimax.cloud/39781/uploads/urunresimleri/buyuk/serpme-kahvalti-2-kisilik-659b.jpg',
    desc: `This is one of those dishes whose very image inspires appetites from the pickiest of eaters. Be careful though: the dish is pretty spicy and tastes great when paired with tongue-cooling rice.`,
  },
  {
    id: 7,
    title: 'limonata',
    category: 'soğuk içecekler',
    price: 45,
    img:
      'https://www.brooklynyalova.com/uploads/1691765582-limonata.jpg',
    desc: ``,
  },
    {
      id: 8,
      title: 'türk kahvesi',
      category: 'sıcak içecekler',
      price: 45,
      img: 'https://www.hacimustafa.com/turk-kahvesi-100g-776-14-B.jpg',
      desc: ``,
    },
      {
        id: 9,
        title: 'bardakta çay',
        category: 'sıcak içecekler',
        price: 18,
        img: 'https://www.camlicalezzetleri.com/images/urunler/Bardakta-Cay-resim-257.jpg',
        desc: ``,
      },
      {
        id: 10,
        title: 'fincanda çay',
        category: 'sıcak içecekler',
        price: 18,
        img: 'https://www.binbirevimavm.com/wp-content/uploads/2017/08/MAG_4075202.jpg',
        desc: ``,
      },
]
export default menu
