import { useEffect } from "react";
import { useIntl } from "react-intl";

export function useDocL10n(localeConfig) {
  const { locale, formatMessage } = useIntl();

  useEffect(() => {
    document.dir = localeConfig.locales[locale].dir;
    document.title = formatMessage(
      { id: "app_title" },
      { thin: (chunks) => chunks }
    );
  }, [locale, formatMessage, localeConfig.locales]);
}
