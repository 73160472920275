const catalog = [
    {
        key: "1",
        img: './img/kunefe.jpg',
        title: 'Künefeler'
    },
    // {
    //     key: "2",
    //     img: 'https://karekodrestaurantmenu.com/Pictures/3038e725-67cf-43dc-83d8-c77594fc3249.png',
    //     title: 'Cevizli Künefe'
    // },
    {
        key: "3",
        img: 'https://karekodrestaurantmenu.com/Pictures/c8be7ca7-2740-45f2-9c0f-332458ea67b8.jpg',
        title: 'Katmer'
    },
    {
        key: "4",
        img: 'https://karekodrestaurantmenu.com/Pictures/49809408-ea9c-4114-b92b-e92287cf148b.png',
        title: 'Dondurma'
    },
    {
        key: "5",
        img: 'https://karekodrestaurantmenu.com/Pictures/1ad97c55-de64-4d21-a9f0-620f78cf5f4d.png',
        title: 'İçecekler'
    }
];
export default catalog;