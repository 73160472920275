import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Home';
import Demo1 from './demo1/Demo1';
import TitlebarImageList from './yalova_ozdemir_bey_kunefe/ImageList';
import Men from './yalova_ozdemir_bey_kunefe/Menu';
// import Men2 from './yalova_organica/Menu';
// import Men3 from './menu/Menu';
import LocaleMenu from './menu/LocaleMenu';

function App() {

        return (

                <Router>
                        <div className="App">
                                <Routes>
                                        <Route exact path='/' element={< Home />}></Route>
                                        <Route exact path='/demo1' element={< Demo1 />}></Route>
                                        <Route exact path='/yalova_ozdemir_bey_kunefe' element={< TitlebarImageList />}></Route>
                                        <Route exact path='/yalova_ozdemir_bey_kunefe/menu' element={< Men />}></Route>
                                        <Route exact path='/yalova_organica' element={< LocaleMenu id="000002" />}></Route>
                                        {/* <Route exact path='/yalova_organica/menu' element={< Men2 />}></Route> */}
                                        <Route exact path='/restaurant' element={< LocaleMenu />}></Route>

                                </Routes>
                        </div>
                </Router>

        );

}

export default App;