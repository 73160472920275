const menu = [
    {
        key: "1",
        img: 'https://karekodrestaurantmenu.com/Pictures/a2c3f94d-bd82-47a9-b2bc-7924c42db821.jpg',
        title: 'Klasik Künefe',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 110
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 230
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 330
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 420
            }
        ]
    },
    {
        key: "2",
        img: 'https://karekodrestaurantmenu.com/Pictures/c924a88f-1e01-4564-bc63-61b01d12a5d7.png',
        title: 'Hasır Künefe',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 150
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 310
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 410
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 500
            }
        ]
    },
    {
        key: "3",
        img: 'https://karekodrestaurantmenu.com/Pictures/af3e8967-4447-4321-ae10-d642133775ba.jpg',
        title: 'Gaziantep Çullama',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 160
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 330
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 430
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 520
            }
        ]
    },
    {
        key: "4",
        img: 'https://karekodrestaurantmenu.com/Pictures/3de941d7-c0e2-4eb0-9a86-fc59ce908a6b.png',
        title: 'Fıstıklı Kadayıf',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 160
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 330
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 430
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 520
            }
        ]
    },
    {
        key: "5",
        img: 'https://karekodrestaurantmenu.com/Pictures/302c5037-53f2-4d15-9be8-0e21ea150c69.png',
        title: 'Atom',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 170
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 350
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 450
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 540
            }
        ]
    },
    {
        key: "6",
        img: 'https://karekodrestaurantmenu.com/Pictures/fd906eee-fc96-4d3a-873c-9dbe8750c62c.png',
        title: 'Billuriye',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 175
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 360
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 460
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 550
            }
        ]
    },
    {
        key: "7",
        img: 'https://karekodrestaurantmenu.com/Pictures/de1c9a14-5c17-4ca7-9766-193fc493b6c6.png',
        title: 'Tüm Fıstık',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 185
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 370
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 470
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 560
            }
        ]
    },
    {
        key: "8",
        img: 'https://karekodrestaurantmenu.com/Pictures/a3fe1a95-d8da-4522-bf03-6d8466c52576.png',
        title: 'Yarı Tüm Yarı Billuriye',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 180
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 370
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 470
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 560
            }
        ]
    },
    {
        key: "9",
        img: 'https://karekodrestaurantmenu.com/Pictures/c0ca5c31-555c-4e7b-9dd4-518a048f4111.jpg',
        title: 'Padişah Tabağı',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 240
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 490
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 590
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 680
            }]
    },
    {
        key: "10",
        img: 'https://karekodrestaurantmenu.com/Pictures/84cff8a7-de16-4c79-aa2d-5015a7cedbac.png',
        title: 'Ayintap Özel (Dondurmalı)',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 200
            }
        ]
    },
    {
        key: "11",
        img: 'https://karekodrestaurantmenu.com/Pictures/b369c30d-8371-4e79-85e3-d28d7d4d2cc7.png',
        title: 'Antep Burma',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 200
            }
        ]
    },
    {
        key: "12",
        img: 'https://karekodrestaurantmenu.com/Pictures/28281125-6ea8-4c83-9285-dfe56de02345.png',
        title: 'Cevizli Künefe',
        catalogId: "1",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 120
            },
            {
                key: 2,
                title: "İki kişilik porsiyon",
                price: 250
            },
            {
                key: 3,
                title: "Dört kişilik porsiyon",
                price: 350
            },
            {
                key: 4,
                title: "Altı kişilik porsiyon",
                price: 440
            }]
    },
    {
        key: "13",
        img: 'https://karekodrestaurantmenu.com/Pictures/c8be7ca7-2740-45f2-9c0f-332458ea67b8.jpg',
        title: 'Yaprak Katmer',
        catalogId: "3",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 225
            }]
    },
    {
        key: "14",
        img: 'https://karekodrestaurantmenu.com/Pictures/ab5f4fe5-8a7e-4457-aa12-eeebc90984a1.jpg',
        title: 'Simit Katmer',
        catalogId: "3",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 250
            }]
    },
    {
        key: "15",
        img: 'https://karekodrestaurantmenu.com/Pictures/0c815ab2-2dbc-4e74-90ce-fcded747ce1a.jpg',
        title: 'Çikolatalı Katmer',
        catalogId: "3",
        priceList: [
            {
                key: 1,
                title: "Tek kişilik porsiyon",
                price: 160
            },
            {
                key: 1,
                title: "İki kişilik porsiyon",
                price: 290
            }]
    },
    {
        key: "16",
        img: 'https://karekodrestaurantmenu.com/Pictures/49809408-ea9c-4114-b92b-e92287cf148b.png',
        title: 'Kesme Dondurma',
        catalogId: "4",
        priceList: [
            {
                key: 1,
                title: "Porsiyon",
                price: 35
            }]
    },
    {
        key: "17",
        img: 'https://karekodrestaurantmenu.com/Pictures/1ad97c55-de64-4d21-a9f0-620f78cf5f4d.png',
        title: 'Sıcak İçecekler',
        catalogId: "5",
        priceList: [
            { key: 1, title: "Çay", price: 10 },
            { key: 1, title: "Duble Çay", price: 25 },
            { key: 1, title: "Türk kahvesi", price: 40 },
            { key: 1, title: "Menegiç kahvesi (Sütlü)", price: 45 },
            { key: 1, title: "Nescafe", price: 40 }
        ]
    },
    {
        key: "18",
        img: 'https://www.pazarlamasyon.com/wp-content/uploads/2018/06/gulizar-bahce-colafantasprite-1.png',
        title: 'Soğuk İçecekler',
        catalogId: "5",
        priceList: [
            { key: 1, title: "Su", price: 5 },
            { key: 1, title: "Maden suyu", price: 15 },
            { key: 1, title: "Meyveli soda", price: 20 },
            { key: 1, title: "Niğde gazoz", price: 25 },
            { key: 1, title: "Şişe kola", price: 35 },
            { key: 1, title: "Soğuk çay", price: 30 }
        ]
    }
];

export default menu;