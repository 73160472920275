import { useContext } from "react";
import { LocaleContext } from "./LocaleContext";
import { setStoredLocale } from "./stored-locale";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, InputLabel, MenuItem } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function LangSwitcher({localeConfig}) {
  const { locale, setLocale } = useContext(LocaleContext);

  return (

    <FormControl size='small'>
      <InputLabel id="demo-simple-select-label">
        <FormattedMessage id='language_title' defaultMessage='language_title' />

      </InputLabel>
      <Select
        bluronselect="true"
        //  variant="standard"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={locale}
        // defaultValue={countries.at(0)}

        // label="Dil"
        onChange={(e) => {
          setLocale(e.target.value);
          setStoredLocale(e.target.value);
        }}
      >
        {Object.keys(localeConfig.locales).map((loc) => (
          <MenuItem value={loc} key={loc}>

            <Box sx={{ '& > img': { mx: 1 } }} >
              {/* <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${localeConfig.locales[loc].countryCode.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${localeConfig.locales[loc].countryCode.toLowerCase()}.png`}
                alt=""
              /> */}
              {localeConfig.locales[loc].name}
            </Box>
          </MenuItem>
        ))}

        {/* {countries.map((country) => (
          <MenuItem key={country.code} value={country} label={country.label}>

            <Box sx={{ '& > img': { mr: 1 } }} >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                alt=""
              />
              
              {country.code}
              
            </Box>


          </MenuItem>
        ))} */}

      </Select>
    </FormControl>

    // <div className="flex relative items-center bg-amber-900 text-amber-200 rounded-md py-[2px] ps-1 after:content-[''] after:w-3 after:h-3 after:bg-amber-200 after:clip-arrow after:block after:absolute after:top-[10px] after:end-2">
    //   <img
    //     src="/noun-language-4114572.svg"
    //     alt="Langage switcher icon"
    //     className="w-4 h-4 me-1"
    //   />

    //   <select
    //     value={locale}
    //     onChange={(e) => {
    //       setLocale(e.target.value);
    //       setStoredLocale(e.target.value);
    //     }}
    //     className="bg-transparent appearance-none pe-6"
    //   >
    //     {Object.keys(locales).map((loc) => (
    //       <option value={loc} key={loc}>
    //         {locales[loc].name}
    //       </option>
    //     ))}
    //   </select>
    // </div>
  );
}
